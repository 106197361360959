import axios from "axios";
import authHeader from "../../auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL;

class LeaderService {
  getItems(page, limit, query) {
    if (query) {
      return axios.get(API_URL + `admin/leaders?page=${page}&limit=${limit}&q=${query}`, { headers: authHeader() });
    } else {
      return axios.get(API_URL + `admin/leaders?page=${page}&limit=${limit}`, { headers: authHeader() });
    }
  }

  get(id) {
    return axios.get(API_URL + `admin/leaders/${id}`, { headers: authHeader() });
  }

  async create(formdata) {
    const { data } = await axios.post(
      API_URL + `admin/leaders`,
      { ...formdata },
      {
        headers: authHeader(),
      }
    );
    return data;
  }

  save(c) {
    return axios.post(API_URL + `admin/leaders/${c.id}`, c, { headers: authHeader() });
  }

  delete(id) {
    return axios.delete(API_URL + `admin/leaders/${id}`, { headers: authHeader() });
  }

}

export default new LeaderService();
